<template>
  <div class="infinite-loading">
    <slot :all-data="allData" />
    <div ref="bottomIndicator" class="bottom-indicator" />
  </div>
</template>

<script setup lang="ts" generic="T">
const props = defineProps<{
  data?: T[];
  hasMoreToLoad: boolean;
  pixelOffset: number;
}>();

// Called when the bottom of the component is [pixelOffset] pixels from the bottom of the viewport
const emit = defineEmits(["loadMore"]);

// Store all data in an array
const allData = ref<T[]>(props.data ?? []) as Ref<T[]>;

// Set up the intersectionObserver to detect when the bottom of the component is [pixelOffset] pixels from the bottom of the viewport
const bottomIndicator = ref<HTMLElement | null>(null);

const { pause, resume, isActive } = useIntersectionObserver(
  bottomIndicator,
  (entries) => {
    // Check if the data is loaded already
    if (allData.value.length === 0) return;

    // Check if the bottom indicator is intersecting
    const isInterSecting = entries[0].isIntersecting;

    if (isInterSecting) {
      emit("loadMore");
    }
  },
  {
    rootMargin: `${props.pixelOffset}px`,
  }
);

// Is the bottom indicator in the viewport?
const isBottomIndicatorVisible = useElementVisibility(bottomIndicator);

// When the data prop changes, append the new data to the stored data
watch(
  () => props.data,
  async (newData) => {
    allData.value = [...allData.value, ...newData];
    await nextTick();
    if (isBottomIndicatorVisible.value && props.hasMoreToLoad) {
      emit("loadMore");
    }
  }
);

// Stop the intersectionObserver when there is no more data to load
watch(
  () => props.hasMoreToLoad,
  (hasMoreToLoad) => {
    if (!hasMoreToLoad) {
      pause();
    } else if (!isActive.value) {
      resume();
    }
  },
  { immediate: true }
);

// Reset the data (used when inputs other then the pagination change)
const resetData = () => {
  allData.value = [];
};
defineExpose({ resetData });
</script>

<style lang="scss" scoped>
.infinite-loading {
  position: relative;
}
.bottom-indicator {
  position: absolute;
  bottom: 0;
  width: 1px;
  height: 1px;
}
</style>
